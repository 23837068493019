<template>
<div class="page">
  <div class="pageFuc">
    <div class="left">
      <el-form inline>
        <el-form-item label="关键字">
          <el-input v-model="search.keyword"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="right">
      <el-button type="primary" @click="toRelease">新增</el-button>
    </div>
  </div>
  <el-table :data="articleList">
    <el-table-column label="文章">
      <template slot-scope="scope">
        <el-link :underline="false" @click="toArticleDetail(scope.row.id)">{{scope.row.title}}</el-link>
      </template>
    </el-table-column>
    <el-table-column label="点击率" prop="hit"></el-table-column>
    <el-table-column label="分类" prop="category_name"></el-table-column>
    <el-table-column label="状态">
      <template slot-scope="scope">
        <span>{{scope.row.state == 0?'草稿':'发布'}}</span>
      </template>
    </el-table-column>
    <el-table-column label="发布时间">
      <template slot-scope="scope">
        <span>{{scope.row.create_at | formatDate}}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-link type="danger" :underline="false" @click="del(scope.row.id)">删除</el-link>
      </template>
    </el-table-column>
  </el-table>
  <div class="pageView">
    <el-pagination layout="prev, pager, next" :page-size="search['per-page']" :current-page.sync="search.page" :total="pageCount" @current-change="pageChangeHandle" />
  </div>
</div>
</template>

<script>
import {apiGetBLOGList, apiDelBLOG} from '@/request/api'
export default {
  name: "articleList",
  data(){
    return{
      search:{
        keyword:'',
        'per-page':10,
        page:1
      },
      pageCount:0,
      articleList:[]
    }
  },
  filters: {
    formatDate(timestamp) {
      var date = new Date(timestamp * 1000);

     var Y = date.getFullYear() + '-';
     var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
     var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
     var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
     var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
     var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
      return Y + M + D + h + m + s;
    }
  },
  activated() {
    this.getArticleList()
  },
  methods:{
    toRelease(){
      this.$router.push({path:'/blog/release'})
    },
    getArticleList(){
        apiGetBLOGList(this.search).then(res => {
        if(res.code == 200){
          console.log(res)
          this.pageCount = res.data.count
          this.articleList = res.data.list
        }
      })
    },
    toArticleDetail(id){
      this.$router.push({path:'/blog/release',query:{id:id}})
    },
    del(id){
      this.$confirm('确定删除该文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          apiDelBLOG({id:id}).then(res => {
          if(res.code == 200){
            this.search.page = 1
            this.getArticleList()
          }
        })
      })
    },
    pageChangeHandle(){
      this.getArticleList()
    },
    searchHandle(){
      this.search.page = 1
      this.getArticleList()
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
.pageFuc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
</style>
